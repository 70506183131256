import React from 'react'
import { Builder, withChildren } from '@builder.io/react'
import { BuilderReviewsSection } from '../../SEMPage/ReviewsSection'
import { BuilderOurTechniciansWithChildren } from '../components/OurTechnicians'
import { CallUsNowBar } from '../components/CallUsNowBar'
import { FAIcon, faIconNameList } from '../components/FAIcon'
import { BookFactsSection } from '../components/cities/Convenient'
import { CityNearYouSection } from '../components/cities/CityNearYou'
import { FAQSection } from '../../CarPage/FAQSection'
import { ReviewsV2Section } from '../../ReviewsV2Section'
import { PromoModal } from '../components/PromoModal'
import { GetAQuoteButton } from '../components/GetAQuoteButton'

Builder.registerComponent(GetAQuoteButton, {
  name: 'Get A Quote Button',
  inputs: [
    { name: 'buttonText', type: 'string', defaultValue: 'Get a Quote' },
    { name: 'textColor', type: 'string', defaultValue: 'white' },
  ]
})

Builder.registerComponent(PromoModal, {
  name: 'Promo Modal',
  inputs: [
    { name: 'header', type: 'string', defaultValue: 'GET 10% OFF YOUR FIRST SERVICE' },
    {
      name: 'subHeader',
      type: 'string',
      defaultValue: 'Save 3.5 hours and up to 30% on shop prices with NuBrakes at-home vehicle service.',
    },
    {
      name: 'buttonHelpText',
      type: 'string',
      defaultValue: "We'll text a discount code to your phone",
    },
    {
      name: 'buttonText',
      type: 'string',
      defaultValue: 'SEND ME A CODE',
    },
    {
      name: 'postSubmissionText1',
      type: 'string',
      defaultValue: 'We sent your 10% off promo code to ',
    },
    {
      name: 'postSubmissionText2',
      type: 'string',
      defaultValue: 'To redeem, you must schedule an appointment within 48 hours, so we hope to see you soon!',
    },
    { name: 'promoContent', type: 'string', defaultValue: '', helperText: 'For internal use' },
    { name: 'delayMS', type: 'number', defaultValue: 1500 },
    { name: 'modalEnabled', type: 'boolean', defaultValue: true },
  ],
})

Builder.registerComponent(BuilderOurTechniciansWithChildren, {
  name: 'Case Study',
  inputs: [
    { name: 'title', type: 'string', defaultValue: 'Our Technicians' },
    {
      name: 'subtitle',
      type: 'richText',
      defaultValue: "Brake repair is what we do. A 'wow' customer experience is what we offer.",
    },
  ],
})

Builder.registerComponent(CallUsNowBar, {
  name: 'Call Us Now Stripe',
  inputs: [
    { name: 'title', type: 'string', defaultValue: 'Schedule A Brake Repair Or Get A Quote Now.' },
    { name: 'phoneNumber', type: 'string', defaultValue: '8558005629' },
    { name: 'backgroundColor', type: 'color', defaultValue: '#f04f23' },
  ],
})

Builder.registerComponent(FAIcon, {
  name: 'Font Awesome Icon',
  inputs: [
    { name: 'icon', helperText: 'DuoTone Kit', type: 'string', enum: [...faIconNameList], defaultValue: 'acorn' },
    { name: 'iconColor', type: 'color', defaultValue: '#f04f23' },
    {
      name: 'iconSize',
      type: 'string',
      enum: ['xs', 'lg', '2x', '3x', '4x', '5x', '6x', '7x', '8x', '9x', '10x'],
      defaultValue: 'lg',
      helperText: 'xs, lg, xl or multiplier of font size',
    },
  ],
})

const BuilderBookFacts = withChildren(BookFactsSection)
Builder.registerComponent(BuilderBookFacts, {
  name: 'Book Facts Section',
  inputs: [
    { name: 'title', type: 'string', defaultValue: 'Book Facts' },
    { name: 'description', type: 'string', defaultValue: 'Book Facts' },
    { name: 'leftColumnTitle', type: 'string', defaultValue: 'Left Column Title' },
    { name: 'leftColumnText', type: 'richText', defaultValue: '<p>Left Column Description</p>' },
    { name: 'rightColumnTitle', type: 'string', defaultValue: 'Right Column Title' },
    { name: 'rightColumnText', type: 'string', defaultValue: 'Right Column Description' },
  ],
})

Builder.registerComponent(CityNearYouSection, {
  name: 'Cities Near You',
  inputs: [
    {
      name: 'title',
      type: 'string',
      defaultValue:
        'We’re the closest mobile brake repair service in South Houston near you. Skip waiting in line at the repair shop, we come to you!',
    },
    {
      name: 'description',
      type: 'richText',
      defaultValue:
        '<p>We bring the brake shop to you. Find the best <a href="https://nubrakes.com/brake-repair-near-me/">brake repair near you</a> now.</p>',
    },
    {
      name: 'phoneNumberTxt',
      type: 'string',
      defaultValue: '(855) 800-5629',
    },
    {
      name: 'phoneNumber',
      type: 'string',
      defaultValue: '8558005629',
    },
    {
      name: 'buttonText',
      type: 'string',
      defaultValue: 'Get A Free Brake Service Estimate',
    },
    {
      name: 'buttonColor',
      type: 'color',
      defaultValue: '#000',
    },
    { name: 'listOfMinorCities', type: 'string' },
  ],
  defaultStyles: {
    textAlign: 'center',
  },
})

const BuilderCityFAQSection = ({ cityName }) => (
  <FAQSection
    title={`Frequently Asked ${cityName} Repair Questions`}
    description={`Learn more about our ${cityName} brake repair operations`}
    faqs={[
      {
        question: `How is NuBrakes different from other ${cityName} brake repairs near me?`,
        answer: `NuBrakes provides a modern approach to brake repair and replacements by providing an on-demand style service that comes to you. Unlike traditional ${cityName} brake service shops that require to you to wait in the lobby while the brake repair is being done, use poor quality parts, and lack transparent pricing; NuBrakes will start by sending you a transparent quote, upon your approval, a NuBrakes ASE-certified technician will come to you with everything they need to repair your brakes. We want to save you time and provide you with a trustworthy and transparent diagnosis and repair quote for your ${cityName}. To request a quote, simply click here, fill out your vehicle information and brake symptoms, and we’ll send you a quote for your ${cityName} brake service in under an hour.`,
      },
      {
        question: `Are your ${cityName} brake service technicians ASE-certified?`,
        answer: `Absolutely. Every NuBrakes ${cityName} brake repair technician has years of industry experience and has been certified by The National Institute for Automotive Service Excellence (ASE). Our technicians have a depth of knowledge in all forms of vehicle repair, but brake repair is our core service and specialty.`,
      },
      {
        question: `Why should I choose mobile brake repair?`,
        answer: `We’re a firm believer that your time, energy, and a trustworthy service provider is much more valuable than spending your Saturday afternoon at the brake repair shop. We’ve built the NuBrakes service so that you can efficiently receive a quote and schedule a mobile brake repair service that comes to you, with just a few clicks. There’s not enough time in the day and we think you should spend more time doing what you love and waiting at the brake repair shop is not it. Not to mention, many brake repair shops will quote you more than you need to spend, and often suggest you repair or replace brake parts when it is not necessary. What’s better than saving time and money?`,
      },
      {
        question: `What type of brake pads does NuBrakes use?`,
        answer: `High-quality parts are of the utmost importance when repairing your brakes. At NuBrakes, most frequently we are using professional ceramic or semi-metallic brake pads. These parts offer exceptional performance and are backed by our 24 Month/24,000 Mile warranty.`,
      },
      {
        question: `How long does it take to repair or replace my brakes?`,
        answer: `On average, your mobile brake repair service will take 45-90 minutes, with the average appointment lasting around an hour. If you have any time constraints, we’re happy to coordinate around your schedule.`,
      },
      {
        question: `What should I expect during my brake repair appointment?`,
        answer: `After you request a free brake repair quote, you’ll receive an email within 1 hour that will outline our best guess (based on the symptoms you provided) for the repairs and estimated cost. On the day of your appointment, a NuBrakes technician will arrive with all the tools they need to evaluate your brakes in person. Following the in-person evaluation, your technician will provide you with a formal repair quote. Upon your approval, the technician will complete the brake repair procedure.`,
      },
      {
        question: `Do you service all ${cityName} models?`,
        answer: `Yes, we’re able to provide brake service on nearly all ${cityName} models and trims! All of our mobile brake replacement technicians have all of the necessary tools and experience to repair your brakes no matter the model/year. So whether you’re looking for service on a 328i or an M-class, we can take care of your brake service needs.`,
      },
      {
        question: `Do you offer a warranty on the brake repairs?`,
        answer: `Yes, your NuBrakes mobile brake repair is backed by our 24 month, 24,000 mile warranty.`,
      },
    ]}
  />
)

Builder.registerComponent(BuilderCityFAQSection, {
  name: 'City Page FAQ',
  inputs: [{ name: 'cityName', type: 'string' }],
})

const BuilderReviewSection = ({ cityName, title }) => (
  <ReviewsV2Section
    title={title}
    first={{
      review: `We received fantastic, quick service, at a fair price. We got quite a few quotes and NuBrakes was the best price and it turns out, the quickest and best service. Our technician, Mike, was knowledgeable and friendly and got the job done quickly - all at my office. We would definitely recommend them and use the service again.`,
      from: `${cityName}`,
      reviewer: `Tami F.`,
    }}
    second={{
      from: `${cityName}`,
      reviewer: `Clinton B.`,
      review: `This service is awesome. You are paying for fast, convenient and good quality work. Communication with Walker was immediate and the technician Micheal was done in half the time that was quoted. I will use NuBrakes again in the future and recommend it to everyone. I mean, they come to you, that was what sold it for me.`,
    }}
    third={{
      review: `Without a doubt would recommend NuBrakes to anyone that needs brakes done. Service tech showed up on time and was professional and quick. And the conveniences having them come to you is hard to beat. Not to mention the price is close to half what you’d pay at a pep boys or any other body shop. Can’t beat it.`,
      from: `${cityName}`,
      reviewer: `William T.`,
    }}
  />
)

Builder.registerComponent(BuilderReviewsSection, {
  name: 'Reviews Section',
  inputs: [
    { name: 'title', type: 'string', defaultValue: 'Customer Reviews' },
    { name: 'subtitle', type: 'string' },
    { name: 'phoneNumber', type: 'string', defaultValue: '(855) 800-5629' },
    {
      name: 'reviews',
      type: 'list',
      subFields: [
        { name: 'review', type: 'longText', defaultValue: 'Enter Review' },
        {
          name: 'reviewSource',
          type: 'string',
          enum: ['google', 'facebook', 'yelp', 'nubrakes'],
          defaultValue: 'google',
        },
        { name: 'customerLocation', type: 'string', defaultValue: 'Austin, Texas' },
        { name: 'customerName', type: 'string', defaultValue: 'Stephen T.' },
        { name: 'reviewLink', type: 'string', defaultValue: 'https://goo.gl/maps/Ks8Tec69t5x5sFX68' },
      ],
    },
  ],
})

Builder.registerComponent(BuilderReviewSection, {
  name: 'City Review Section',
  inputs: [
    { name: 'title', type: 'string' },
    { name: 'cityName', type: 'string' },
  ],
})
