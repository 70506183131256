import React from 'react'
import Image from 'next/image'
import Link from 'next/link'
import { Container } from '../../Container'
import { StarsAndRatings } from '../../SEMPage/StarsAndRatings'
import { useQueryParam } from '../../../hooks/useQueryParam'
import backgroundImage from '../../../../public/img/nubrakes-van-house-800-num.jpg'
import { propOr } from 'ramda'
import { setContentWithFallbacks } from '../templates/SEMLayoutTemplate'
import * as changeCase from 'capital-case'
import { CityPhoneNumberData } from '../../SEMPage/CityPhoneNumberData'
import { formatPhoneNumber } from '../../utils/string-utils'
import { getTitleFromUTMS } from '../../SEMPage/getTitleFromUTMS'

export const HomeScheduleServiceFormHeader: React.FC<{
  title: string
  rating: string
  reviewCount: number
  subheading: string
  children?: React.ReactNode
  ratingsText?: string
  showGradientOverlay?: boolean
  bgImage?: string
  useDynamicTitle?: boolean
  eventVariationLabel?: string
  showVideo?: boolean
  video?: string
}> = ({
  title,
  rating,
  reviewCount,
  subheading,
  children,
  ratingsText,
  showGradientOverlay,
  bgImage,
  useDynamicTitle,
  showVideo = false,
  video,
}) => {
  const query = useQueryParam()
  const city: string = propOr('', 'city', query)
  const type: string = propOr('', 'type', query)
  const make: string = propOr('', 'make', query)
  let content: string = propOr('', 'utm_content', query)
  content = setContentWithFallbacks(type, content)
  const formalCity = city ? changeCase.capitalCase(city) : ''
  const phoneNumber =
    city && CityPhoneNumberData[city] ? CityPhoneNumberData[city!].phoneNumber : CityPhoneNumberData.default.phoneNumber
  const phoneNumberTxt = formatPhoneNumber(phoneNumber)

  const dynamicTitle = getTitleFromUTMS({
    formalCity,
    phoneNumber,
    phoneNumberTxt,
    type,
    content,
    make,
    rating,
    reviewCount,
  })
  return (
    <>
      <section className="relative overflow-hidden">
        <div
          style={{
            position: 'absolute',
            width: '100%',
            height: '100%',
            zIndex: -100,
            top: 0,
            left: 0,
          }}
        >
          <div className="py-8 flex flex-col justify-center align-center w-full h-full min-h-[60vh] relative">
            <Image
              src={bgImage ?? backgroundImage}
              layout="fill"
              objectFit="cover"
              objectPosition={'bottom center'}
              priority={true}
              alt="NuBrakes Mobile Repair"
            />
            {showGradientOverlay && (
              <div
                style={{
                  backgroundColor: 'rgba(0,0,0,.75)',
                  position: 'absolute',
                  width: '100%',
                  height: '100%',
                  zIndex: 1,
                  top: 0,
                  left: 0,
                }}
              />
            )}
          </div>
        </div>
        <Container className="text-center z-10">
          <div className="grid lg:grid-cols-2 gap-4">
            <div className={`text-center lg:text-left max-w-xl flex flex-col ${showVideo? 'lg:col-span-2 items-center justify-self-center' : 'col-span-1 justify-center'}`}>
              {useDynamicTitle ? (
                <h1 className="text-2xl sm:text-2xl md:text-4xl text-white sm:mb-0">{dynamicTitle}</h1>
              ) : (
                <h1 className="text-2xl sm:text-2xl md:text-4xl text-white sm:mb-0 px-2">{title}</h1>
              )}
              <p
                style={{ fontSize: 18, marginBottom: 10 }}
                className="subheading text-white leading-tight sm:leading-normal text-sm pb-0 ml-2"
              >
                {subheading}
              </p>
              <div
                className="w-auto lg:inline-block hidden mr-auto p-3 text-center"
                style={{
                  backgroundColor: 'rgba(0, 0, 0, 0.69)',
                  borderRadius: 6,
                }}
              >
                <StarsAndRatings />
                <p
                  style={{
                    color: '#cbd0d3',
                    lineHeight: 1,
                  }}
                >
                  {`NuBrakes is rated ${rating}/5 by `}
                  <Link href={{ pathname: '/reviews/', query }} prefetch={false}>
                    <a
                      style={{
                        color: '#cbd0d3',
                        textDecoration: 'underline',
                      }}
                    >{`${reviewCount}`}</a>
                  </Link>
                  {` ${ratingsText}`}
                </p>
              </div>
            </div>
            { showVideo && (
              <div className="w-full h-[calc(100%-1.5rem)] pb-[62%] mt-6 hidden lg:flex self-center relative">
                <div className="absolute top-0 left-0 right-0 bottom-0">
                    <iframe
                      className="w-full h-full"
                      src={video}
                      title="YouTube video player"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    />
                  </div>
              </div>
            )}
            <div className="mt-0 md:mt-6 flex flex-col">
              <div style={{ backgroundColor: 'white' }} className="flex flex-col p-6 lg:mt-0 text-left">
                {children}
              </div>
              { showVideo && (
                <div className="w-full pb-[52%] flex lg:hidden justify-center mt-6 relative">
                  <div className="absolute top-0 left-0 right-0 bottom-0">
                    <iframe
                      className="w-full h-full"
                      src={video}
                      title="YouTube video player"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    />
                  </div>
                </div>
              )}
              <div className="mt-5 block lg:hidden text-center">
                <div
                  style={{
                    backgroundColor: 'rgba(0, 0, 0, 0.69)',
                    borderRadius: 6,
                  }}
                  className="block px-3 py-2"
                >
                  <StarsAndRatings />
                  <p
                    style={{
                      color: '#cbd0d3',
                      lineHeight: 1,
                    }}
                  >
                    {`NuBrakes is rated ${rating}/5 by `}
                    <Link href={{ pathname: '/reviews/', query }} prefetch={false}>
                      <a
                        style={{
                          color: '#cbd0d3',
                          textDecoration: 'underline',
                        }}
                      >{`${reviewCount} brake repair customers`}</a>
                    </Link>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </section>
    </>
  )
}
